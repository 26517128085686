import React from 'react';

import About from '@myungsoo/base/pages/admin/About';

import AdminLayout from '../../components/AdminLayout';
import Seo from '../../components/Seo';

import * as config from '../../../config';

export default () => (
  <AdminLayout>
    <Seo title="Admin" />
    <About id="about" config={config} />
  </AdminLayout>
);
