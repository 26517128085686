import 'firebaseui/dist/firebaseui.css';

import _ from 'lodash';
import React from 'react';

export default function useAuth(firebaseUiContainerSelector) {
  const [initialized, setInitialized] = React.useState(false);
  const [firebase, setFirebase] = React.useState();
  const [firebaseui, setFirebaseui] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    (async () => {
      setFirebase(await import('firebase/app'));
      setFirebaseui(await import('firebaseui'));
    })();
  }, []);

  React.useEffect(() => {
    if (!firebase || !firebaseui) {
      return;
    }

    return firebase.auth().onAuthStateChanged(async (usr) => {
      try {
        if (!usr) {
          setUser();

          const ui =
            firebaseui.auth.AuthUI.getInstance() ||
            new firebaseui.auth.AuthUI(firebase.auth());

          ui.start(firebaseUiContainerSelector, {
            signInOptions: [
              {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false,
              },
            ],
            signInSuccessUrl: window.location.href,
          });

          return;
        }

        const userInfo = {
          displayName: usr.displayName,
          email: usr.email,
          emailVerified: usr.emailVerified,
          photoURL: usr.photoURL,
          isAnonymous: usr.isAnonymous,
        };

        const db = firebase.firestore();
        const userRef = db.collection('users').doc(usr.uid);

        const userSnapshot = await userRef.get();

        if (userSnapshot.exists) {
          await userRef.update(userInfo);
        } else {
          await userRef.set({
            ...userInfo,
            admin: false,
          });
        }

        setUser({
          ...userSnapshot.data(),
          ...userInfo,
          uid: usr.uid,
        });
      } finally {
        setInitialized(true);
      }
    });
  }, [firebase, firebaseui, firebaseUiContainerSelector]);

  React.useEffect(() => {
    if (!firebase || !user) {
      return;
    }

    return firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .onSnapshot((snapshot) => {
        const newUser = {
          ...snapshot.data(),
          uid: user.uid,
        };

        if (!_.isEqual(newUser, user)) {
          setUser(newUser);
        }
      });
  }, [firebase, user]);

  const signOut = React.useCallback(async () => {
    if (!firebase) {
      return;
    }
    const auth = firebase.auth();
    await auth.signOut();
  }, [firebase]);

  return {
    initialized,
    user,
    permitted: !!(user && user.admin),
    signOut,
  };
}
